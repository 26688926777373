import {lazy, FC} from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import {useUserInformation} from "./util/useUserInformation";
import {LayoutAuthorized} from "./layouts/LayoutAuthorized/LayoutAuthorized";

const AdminOverviewRoutes = lazy(() =>
    import("./pages/admin-overview/routes").then((exports) => ({
        default: exports.OverviewRoutes,
    }))
);
const AdminLinkRoutes = lazy(() =>
    import("./pages/admin-links/routes").then((exports) => ({
        default: exports.LinkRoutes,
    }))
);
const AdminFaqRoutes = lazy(() =>
    import("./pages/admin-faqs/routes").then((exports) => ({
        default: exports.FaqRoutes,
    }))
);
const AdminProductRoutes = lazy(() =>
    import("./pages/admin-products/routes").then((exports) => ({
        default: exports.ProductRoutes,
    }))
);
const AdminReportRoutes = lazy(() =>
    import("./pages/admin-reports/routes").then((exports) => ({
        default: exports.ReportRoutes,
    }))
);
const UserManagementRoutes = lazy(() =>
    import("./pages/admin-user/routes").then((exports) => ({
        default: exports.UserManagementRoutes,
    }))
);
const AdminContactListRoutes = lazy(() =>
    import("./pages/admin-contact-list/routes").then((exports) => ({
        default: exports.AdminContactListRoutes,
    }))
);
const AdminAmparexImportReportsRoutes = lazy(() =>
    import("./pages/admin-amparex-import-reports/routes").then((exports) => ({
        default: exports.AmparexImportReportsRoutes,
    }))
);
const AdminOvertimeRoutes = lazy(() =>
    import("./pages/admin-overtime/routes").then((exports) => ({
        default: exports.OvertimeRoutes,
    }))
);
const AdminTimeRoutes = lazy(() =>
    import("./pages/admin-time/routes").then((exports) => ({
        default: exports.TimeRoutes,
    }))
);

const AudiologistOverviewRoutes = lazy(() =>
    import("./pages/audiologist-overview/routes").then((exports) => ({
        default: exports.OverviewRoutes,
    }))
);
const AudiologistReportRoutes = lazy(() =>
    import("./pages/audiologist-reports/routes").then((exports) => ({
        default: exports.ReportRoutes,
    }))
);
const AudiologistStockRoutes = lazy(() =>
    import("./pages/audiologist-stock/routes").then((exports) => ({
        default: exports.StockRoutes,
    }))
);
const AudiologistStockStatementRoutes = lazy(() =>
    import("./pages/audiologist-stock-statement/routes").then((exports) => ({
        default: exports.StockStatementRoutes,
    }))
);
// const AudiologistOvertimeRoutes = lazy(() =>
//     import("./pages/audiologist-overtime/routes").then((exports) => ({
//         default: exports.OvertimeRoutes,
//     }))
// );
const AudiologistGpsRoutes = lazy(() =>
    import("./pages/audiologist-gps/routes").then((exports) => ({
        default: exports.GpsRoutes,
    }))
);

// const WarehouseWorkerScan = lazy(() =>
//   import("./pages/warehouse-worker-scan/routes").then((exports) => ({
//     default: exports.ScanRoutes,
//   }))
// );
// const WarehouseWorkerStock = lazy(() =>
//   import("./pages/warehouse-worker-stock/routes").then((exports) => ({
//     default: exports.StockRoutes,
//   }))
// );
const WarehouseWorkerStock2 = lazy(() =>
    import("./pages/warehouse-worker-stock2/routes").then((exports) => ({
        default: exports.Stock2Routes,
    }))
);
const WarehouseWorkerStockMove = lazy(() =>
    import("./pages/warehouse-worker-stock-move/routes").then((exports) => ({
        default: exports.StockMoveRoutes,
    }))
);
const WarehouseWorkerClientList = lazy(() =>
    import("./pages/warehouse-worker-client-list/routes").then((exports) => ({
        default: exports.ClientListRoutes,
    }))
);
const WarehouseWorkerStockTracking = lazy(() =>
    import("./pages/warehouse-worker-stock-tracking/routes").then((exports) => ({
        default: exports.StockTrackingRoutes,
    }))
);
const WarehouseWorkerStockStatements = lazy(() =>
    import("./pages/warehouse-worker-stock-statement/routes").then((exports) => ({
        default: exports.StockStatementRoutes,
    }))
);
// const WarehouseWorkerStockWarnings = lazy(() =>
//   import("./pages/warehouse-worker-stock-warnings/routes").then((exports) => ({
//     default: exports.StockWarningsRoutes,
//   }))
// );
// const WarehouseWorkerReturnNotifications = lazy(() =>
//   import("./pages/warehouse-worker-return-notifications/routes").then((exports) => ({
//     default: exports.ReturnNotificationsRoutes,
//   }))
// );
const WarehouseWorkerImportArticleItems = lazy(() =>
    import("./pages/warehouse-worker-import-article-items/routes").then((exports) => ({
        default: exports.ImportArticleItemsRoutes,
    }))
);

const MixedProductRoutes = lazy(() =>
    import("./pages/mixed-products/routes").then((exports) => ({
        default: exports.ProductRoutes,
    }))
);
const MixedLinkRoutes = lazy(() =>
    import("./pages/mixed-links/routes").then((exports) => ({
        default: exports.LinkRoutes,
    }))
);
const MixedFaqRoutes = lazy(() =>
    import("./pages/mixed-faq/routes").then((exports) => ({
        default: exports.FaqRoutes,
    }))
);
const MixedAudiologistStockRoutes = lazy(() =>
    import("./pages/mixed-audiologist-stock/routes").then((exports) => ({
        default: exports.AudiologistStockRoutes,
    }))
);
const MixedCustomerListRoutes = lazy(() =>
    import("./pages/mixed-customer-lists/routes").then((exports) => ({
        default: exports.CustomerListsRoutes,
    }))
);
const MixedOrderRoutes = lazy(() =>
    import("./pages/audiologist-order/routes").then((exports) => ({
        default: exports.OrderRoutes,
    }))
);
const MixedUpdateDataRoutes = lazy(() =>
    import("./pages/mixed-update-data/routes").then((exports) => ({
        default: exports.UpdateDataRoutes,
    }))
);

const AllContactListRoutes = lazy(() =>
    import("./pages/all-contact-list/routes").then((exports) => ({
        default: exports.ContactListRoutes,
    }))
);

const AllCalculatorRoutes = lazy(() =>
    import("./pages/all-calculator/routes").then((exports) => ({
        default: exports.CalculatorRoutes,
    }))
);

const AllCustomerDataRoutes = lazy(() =>
    import("./pages/all-customer-data/routes").then((exports) => ({
        default: exports.CustomerDataRoutes,
    }))
);

const AllCoworkerReportRoutes = lazy(() =>
    import("./pages/all-coworker-report/routes").then((exports) => ({
        default: exports.CoworkerReportRoutes,
    }))
);

const AllTimeRoutes = lazy(() =>
    import("./pages/all-time/routes").then((exports) => ({
        default: exports.TimeRoutes,
    }))
);

const NoRoleOverviewRoutes = lazy(() =>
    import("./pages/no-role-overview/routes").then((exports) => ({
        default: exports.OverviewRoutes,
    }))
);

const NotFound = lazy(() =>
    import("./pages/Page404").then((exports) => ({default: exports.Page404}))
);

export const RootRouter: FC<{ children?: undefined }> = () => {
    const {
        hasAdminReadAccess,
        hasAudiologReadAccess,
        hasWarehouseWorkerReadAccess,
        hasNoRole,
        userKey: audiologistKey,
        region: {regionKey}
    } = useUserInformation();

    return (
        <LayoutAuthorized>
            {/* content */}
            <Switch>
                {/* admin-areas */}
                {hasAdminReadAccess && (
                    <Route path="/admin/overview" component={AdminOverviewRoutes}/>
                )}
                {hasAdminReadAccess && (
                    <Route path="/admin/reports" component={AdminReportRoutes}/>
                )}
                {hasAdminReadAccess && (
                    <Route path="/audiologist/order" component={MixedOrderRoutes}/>
                )}
                {hasAdminReadAccess && (
                    <Route path="/admin/products" component={AdminProductRoutes}/>
                )}
                {hasAdminReadAccess && (
                    <Route path="/admin/links" component={AdminLinkRoutes}/>
                )}
                {hasAdminReadAccess && (
                    <Route path="/admin/faqs" component={AdminFaqRoutes}/>
                )}
                {hasAdminReadAccess && (
                    <Route path="/admin/users" component={UserManagementRoutes}/>
                )}
                {hasAdminReadAccess && (
                    <Route path="/admin/contact-list" component={AdminContactListRoutes}/>
                )}
                {hasAdminReadAccess && (
                    <Route path="/admin/amparex-import-reports" component={AdminAmparexImportReportsRoutes}/>
                )}
                {hasAdminReadAccess && (
                    <Route path="/admin/overtime" component={AdminOvertimeRoutes}/>
                )}
                {hasAdminReadAccess && (
                    <Route path="/admin/time" component={AdminTimeRoutes}/>
                )}
                {hasAdminReadAccess && (
                    <Route path="/mixed/data" component={MixedUpdateDataRoutes}/>
                )}

                {/* audiologist-areas */}
                {hasAudiologReadAccess && (
                    <Route
                        path="/audiologist/overview"
                        component={AudiologistOverviewRoutes}
                    />
                )}
                {hasAudiologReadAccess && (
                    <Route
                        path="/audiologist/reports"
                        component={AudiologistReportRoutes}
                    />
                )}
                {hasAudiologReadAccess && (
                    <Route path="/audiologist/order" component={MixedOrderRoutes}/>
                )}
                {hasAudiologReadAccess && (
                    <Route
                        path="/:role(audiologist)/products"
                        component={MixedProductRoutes}
                    />
                )}
                {hasAudiologReadAccess && (
                    <Route path="/audiologist/links" component={MixedLinkRoutes}/>
                )}
                {hasAudiologReadAccess && (
                    <Route path="/audiologist/faqs" component={MixedFaqRoutes}/>
                )}
                {hasAudiologReadAccess && (
                    <Route path="/audiologist/stock" component={AudiologistStockRoutes}/>
                )}
                {hasAudiologReadAccess && (
                    <Route path="/audiologist/stock-statements" component={AudiologistStockStatementRoutes}/>
                )}
                {/*{hasAudiologReadAccess && (*/}
                {/*  <Route path="/audiologist/overtime" component={AudiologistOvertimeRoutes} />*/}
                {/*)}*/}
                {hasAudiologReadAccess && audiologistKey === "xkhkk2o0yl2wap11" && (  // xkhkk2o0yl2wap11 = Kristine Kjærsgaard ID: 101013 (DK)
                    <Route
                        path="/audiologist/audiologist-stock"
                        component={MixedAudiologistStockRoutes}
                    />
                )}
                {hasAudiologReadAccess && (
                    <Route path="/mixed/data" component={MixedUpdateDataRoutes}/>
                )}
                {hasAudiologReadAccess && (
                    <Route path="/audiologist/gps" component={AudiologistGpsRoutes}/>
                )}

                {/* warehouse-worker-areas */}
                {hasWarehouseWorkerReadAccess && (
                    <Route
                        path="/:role(warehouse-worker)/products"
                        component={MixedProductRoutes}
                    />
                )}
                {hasWarehouseWorkerReadAccess && (
                    <Route path="/warehouse-worker/links" component={MixedLinkRoutes}/>
                )}
                {hasWarehouseWorkerReadAccess && (
                    <Route path="/warehouse-worker/faqs" component={MixedFaqRoutes}/>
                )}
                {/*{hasWarehouseWorkerReadAccess && (*/}
                {/*  <Route*/}
                {/*    path="/warehouse-worker/scan"*/}
                {/*    component={WarehouseWorkerScan}*/}
                {/*  />*/}
                {/*)}*/}
                {/*{hasWarehouseWorkerReadAccess && (*/}
                {/*  <Route*/}
                {/*    path="/warehouse-worker/stock"*/}
                {/*    component={WarehouseWorkerStock}*/}
                {/*  />*/}
                {/*)}*/}
                {hasWarehouseWorkerReadAccess && (
                    <Route
                        path="/warehouse-worker/stock2"
                        component={WarehouseWorkerStock2}
                    />
                )}
                {hasWarehouseWorkerReadAccess && (
                    <Route
                        path="/warehouse-worker/stock-move"
                        component={WarehouseWorkerStockMove}
                    />
                )}
                {hasWarehouseWorkerReadAccess && (
                    <Route
                        path="/warehouse-worker/client-list"
                        component={WarehouseWorkerClientList}
                    />
                )}
                {hasWarehouseWorkerReadAccess && (
                    <Route
                        path="/warehouse-worker/audiologist-stock"
                        component={MixedAudiologistStockRoutes}
                    />
                )}
                {hasWarehouseWorkerReadAccess && (
                    <Route
                        path="/warehouse-worker/stock-statements"
                        component={WarehouseWorkerStockStatements}
                    />
                )}
                {hasWarehouseWorkerReadAccess && (
                    <Route
                        path="/warehouse-worker/stock-tracking"
                        component={WarehouseWorkerStockTracking}
                    />
                )}
                {hasWarehouseWorkerReadAccess && (
                    <Route
                        path="/warehouse-worker/stock-statements-shared"
                        component={AudiologistStockStatementRoutes}
                    />
                )}
                {/*{hasWarehouseWorkerReadAccess && (*/}
                {/*  <Route*/}
                {/*    path="/warehouse-worker/stock-warnings"*/}
                {/*    component={WarehouseWorkerStockWarnings}*/}
                {/*  />*/}
                {/*)}*/}
                {/*{hasWarehouseWorkerReadAccess && (*/}
                {/*  <Route*/}
                {/*    path="/warehouse-worker/return-notifications"*/}
                {/*    component={WarehouseWorkerReturnNotifications}*/}
                {/*  />*/}
                {/*)}*/}
                {hasWarehouseWorkerReadAccess && (
                    <Route
                        path="/warehouse-worker/import-article-items"
                        component={WarehouseWorkerImportArticleItems}
                    />
                )}
                {hasWarehouseWorkerReadAccess && (
                    <Route
                        path="/mixed/customer-lists"
                        component={MixedCustomerListRoutes}
                    />
                )}
                {hasWarehouseWorkerReadAccess && (
                    <Route path="/mixed/data" component={MixedUpdateDataRoutes}/>
                )}

                {/* all-areas */}
                {regionKey === "reg1onlnkmlsp7kq" && (
                    <Route path="/all/calculator" component={AllCalculatorRoutes}/>
                )}
                {regionKey === "reg1onlnkmlsp7kq" && (
                    <Route path="/all/customer-data" component={AllCustomerDataRoutes}/>
                )}
                <Route path="/all/contact-list" component={AllContactListRoutes}/>
                <Route path="/all/coworker-status" component={AllCoworkerReportRoutes}/>
                <Route path="/all/time" component={AllTimeRoutes}/>

                {/* no-role-areas */}
                <Route path="/no-role/overview" component={NoRoleOverviewRoutes}/>

                {/* admin-redirects */}
                {hasAdminReadAccess && <Redirect exact path="/" to="/admin/overview"/>}
                {hasAdminReadAccess && (
                    <Redirect exact path="/login" to="/admin/overview"/>
                )}
                {hasAdminReadAccess && (
                    <Redirect exact path="/admin" to="/admin/overview"/>
                )}

                {/* audiologist-redirects */}
                {hasAudiologReadAccess && (
                    <Redirect exact path="/" to="/audiologist/overview"/>
                )}
                {hasAudiologReadAccess && (
                    <Redirect exact path="/login" to="/audiologist/overview"/>
                )}
                {hasAudiologReadAccess && (
                    <Redirect exact path="/audiologist" to="/audiologist/overview"/>
                )}

                {/* no-role-redirects */}
                {hasNoRole && (
                    <Redirect exact path="/" to="/no-role/overview"/>
                )}
                {hasNoRole && (
                    <Redirect exact path="/login" to="/no-role/overview"/>
                )}
                {hasNoRole && (
                    <Redirect exact path="/no-role" to="/no-role/overview"/>
                )}

                {/* warehouse-worker-redirects */}
                {hasWarehouseWorkerReadAccess && (
                    <Redirect exact path="/" to="/warehouse-worker/stock-move/location-overview"/>
                )}
                {hasWarehouseWorkerReadAccess && (
                    <Redirect
                        exact
                        path="/warehouse-worker"
                        to="/warehouse-worker/stock-move/location-overview"
                    />
                )}
                {hasWarehouseWorkerReadAccess && (
                    <Redirect exact path="/login" to="/warehouse-worker/stock-move/location-overview"/>
                )}
                {/*{hasWarehouseWorkerReadAccess && (*/}
                {/*  <Redirect exact path="/scan" to="/warehouse-worker/scan" />*/}
                {/*)}*/}
                {/*{hasWarehouseWorkerReadAccess && (*/}
                {/*  <Redirect*/}
                {/*    exact*/}
                {/*    path="/scan/overview"*/}
                {/*    to="/warehouse-worker/scan/overview"*/}
                {/*  />*/}
                {/*)}*/}
                {hasWarehouseWorkerReadAccess && (
                    <Redirect exact path="/stock" to="/warehouse-worker/stock"/>
                )}
                {hasWarehouseWorkerReadAccess && (
                    <Redirect
                        exact
                        path="/stock/overview"
                        to="/warehouse-worker/stock/overview"
                    />
                )}

                {/* fallback */}
                <Route component={NotFound}/>
            </Switch>
        </LayoutAuthorized>
    );
};